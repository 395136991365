import React, { useState } from 'react';
import { TextField, Button, Typography, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const res = await axios.post(`${getBaseUrl()}/api/users/login`, { email, password });
      localStorage.setItem('token', res.data.token);
      navigate('/');
    } catch (error) {
      console.error('Login failed', error);
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #3f51b5, #2196f3)',
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 2,
          padding: 3,
          width: '85%',
          maxWidth: 400,
          borderRadius: 5,
        }}
      >
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
          <span style={{ fontSize: '1.5em', marginRight: '5px' }}>+</span>mi medico online
        </Typography>
        <form onSubmit={handleSubmit}>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Typography
            variant="body2"
            align="right"
            sx={{ cursor: 'pointer', mb: 2 }}
          >
            Olvidé mi contraseña
          </Typography>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: '#4fc3f7',
              '&:hover': { backgroundColor: '#03a9f4' },
            }}
          >
            Entrar
          </Button>
        </form>
        <Typography variant="body2" align="center" sx={{ mt: 2, mb: 2 }}>
          O continuar con
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mb: 2,
          }}
        >
          <IconButton>
            <GoogleIcon />
          </IconButton>
          <IconButton>
            <FacebookIcon />
          </IconButton>
          <IconButton>
            <AppleIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" align="center">
          ¿No tenes una cuenta?{' '}
          <Typography
            component="span"
            sx={{ color: '#1a237e', cursor: 'pointer' }}
            onClick = {() => navigate('/register')}
          >
            Registrarse
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default Login;