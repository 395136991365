import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';
import { Bell, ChevronLeft, Edit2, Plus, X, Save } from 'lucide-react';
import BottomNavbar from './BottomNavbar';

const MiSalud = () => {
  const navigate = useNavigate();
  const [medicalProfile, setMedicalProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);

  useEffect(() => {
    const fetchMedicalProfile = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/medical-profiles`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setMedicalProfile(response.data);
        setEditedProfile(response.data);
      } catch (error) {
        console.error('Error fetching medical profile:', error);
      }
    };

    fetchMedicalProfile();
  }, []);

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setEditedProfile(medicalProfile);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${getBaseUrl()}/api/medical-profiles`,
        editedProfile,
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setMedicalProfile(response.data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating medical profile:', error);
    }
  };

  const handleChange = (field, value) => {
    setEditedProfile({ ...editedProfile, [field]: value });
  };

  const handleArrayChange = (field, index, subfield, value) => {
    const updatedArray = [...editedProfile[field]];
    if (subfield) {
      updatedArray[index] = { ...updatedArray[index], [subfield]: value };
    } else {
      updatedArray[index] = value;
    }
    handleChange(field, updatedArray);
  };

  const addArrayItem = (field) => {
    const newItems = {
      allergies: '',
      currentMedications: { name: '', dosage: '', frequency: '' },
      pastSurgeries: { name: '', date: '', notes: '' },
      medicalConditions: { name: '', diagnosedDate: '', notes: '', isActive: false }
    };
    handleChange(field, [...(editedProfile[field] || []), newItems[field]]);
  };

  const removeArrayItem = (field, index) => {
    handleChange(field, editedProfile[field].filter((_, i) => i !== index));
  };

  if (!medicalProfile) return <div>Loading...</div>;

  const renderField = (field, title, renderItem) => (
    <div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      {isEditing ? (
        <div>
          {editedProfile[field]?.map((item, index) => (
            <div key={index} className="border p-2 rounded mb-2">
              {renderItem(item, index)}
              <button onClick={() => removeArrayItem(field, index)} className="text-red-600">Eliminar</button>
            </div>
          ))}
          <button onClick={() => addArrayItem(field)} className="text-blue-600">
            <Plus className="inline mr-1" />Agregar {title.toLowerCase()}
          </button>
        </div>
      ) : (
        <ul className="list-disc list-inside">
          {medicalProfile[field]?.map((item, index) => (
            <li key={index}>{renderItem(item, index)}</li>
          ))}
        </ul>
      )}
    </div>
  );

  const InputField = ({ value, onChange, placeholder, type = "text" }) => (
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className="w-full p-2 border rounded mb-2"
    />
  );

  return (
    <div className="mx-auto bg-gray-50 min-h-screen pb-20 font-sans">
      <div className="p-6">
        <header className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <ChevronLeft className="text-gray-400 mr-2 cursor-pointer" onClick={() => navigate(-1)} />
            <div className="text-blue-600 font-bold text-xl">Mi Salud</div>
          </div>
          <Bell className="text-gray-400" />
        </header>

        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Perfil Médico</h2>
            {isEditing ? (
            <div className="flex space-x-2">
                <button onClick={handleSave} className="flex items-center bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors">
                <Save className="w-4 h-4 mr-2" />
                Guardar
                </button>
                <button onClick={handleCancel} className="text-red-600 hover:text-red-700 transition-colors">Cancelar</button>
            </div>
            ) : (
            <Edit2 className="text-gray-400 cursor-pointer" onClick={handleEdit} />
            )}
          </div>

          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold mb-2">Tipo de Sangre</h3>
              {isEditing ? (
                <select
                  value={editedProfile.bloodType || ''}
                  onChange={(e) => handleChange('bloodType', e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Seleccionar</option>
                  {['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'].map((type) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              ) : (
                <p>{medicalProfile.bloodType || 'No especificado'}</p>
              )}
            </div>

            {/* New Height Field */}
            <div>
              <h3 className="text-lg font-semibold mb-2">Altura (cm)</h3>
              {isEditing ? (
                <InputField
                  type="number"
                  value={editedProfile.height || ''}
                  onChange={(value) => handleChange('height', value)}
                  placeholder="Altura en cm"
                />
              ) : (
                <p>{medicalProfile.height ? `${medicalProfile.height} cm` : 'No especificado'}</p>
              )}
            </div>

            {/* New Weight Field */}
            <div>
              <h3 className="text-lg font-semibold mb-2">Peso (kg)</h3>
              {isEditing ? (
                <InputField
                  type="number"
                  value={editedProfile.weight || ''}
                  onChange={(value) => handleChange('weight', value)}
                  placeholder="Peso en kg"
                />
              ) : (
                <p>{medicalProfile.weight ? `${medicalProfile.weight} kg` : 'No especificado'}</p>
              )}
            </div>

            {renderField('allergies', 'Alergias', (allergy, index) => 
              isEditing ? (
                <InputField
                  value={allergy}
                  onChange={(value) => handleArrayChange('allergies', index, null, value)}
                  placeholder="Alergia"
                />
              ) : allergy
            )}

            {renderField('currentMedications', 'Medicamentos Actuales', (med, index) => 
              isEditing ? (
                <>
                  <InputField
                    value={med.name}
                    onChange={(value) => handleArrayChange('currentMedications', index, 'name', value)}
                    placeholder="Nombre"
                  />
                  <InputField
                    value={med.dosage}
                    onChange={(value) => handleArrayChange('currentMedications', index, 'dosage', value)}
                    placeholder="Dosis"
                  />
                  <InputField
                    value={med.frequency}
                    onChange={(value) => handleArrayChange('currentMedications', index, 'frequency', value)}
                    placeholder="Frecuencia"
                  />
                </>
              ) : `${med.name} - ${med.dosage} (${med.frequency})`
            )}

            {renderField('pastSurgeries', 'Cirugías Pasadas', (surgery, index) => 
              isEditing ? (
                <>
                  <InputField
                    value={surgery.name}
                    onChange={(value) => handleArrayChange('pastSurgeries', index, 'name', value)}
                    placeholder="Nombre de la cirugía"
                  />
                  <InputField
                    type="date"
                    value={surgery.date}
                    onChange={(value) => handleArrayChange('pastSurgeries', index, 'date', value)}
                  />
                  <textarea
                    value={surgery.notes}
                    onChange={(e) => handleArrayChange('pastSurgeries', index, 'notes', e.target.value)}
                    placeholder="Notas"
                    className="w-full p-2 border rounded mb-2"
                  />
                </>
              ) : `${surgery.name} - ${new Date(surgery.date).toLocaleDateString()}`
            )}

            {renderField('medicalConditions', 'Condiciones Médicas', (condition, index) => 
              isEditing ? (
                <>
                  <InputField
                    value={condition.name}
                    onChange={(value) => handleArrayChange('medicalConditions', index, 'name', value)}
                    placeholder="Nombre de la condición"
                  />
                  <InputField
                    type="date"
                    value={condition.diagnosedDate}
                    onChange={(value) => handleArrayChange('medicalConditions', index, 'diagnosedDate', value)}
                  />
                  <textarea
                    value={condition.notes}
                    onChange={(e) => handleArrayChange('medicalConditions', index, 'notes', e.target.value)}
                    placeholder="Notas"
                    className="w-full p-2 border rounded mb-2"
                  />
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={condition.isActive}
                      onChange={(e) => handleArrayChange('medicalConditions', index, 'isActive', e.target.checked)}
                      className="mr-2"
                    />
                    <label>Activa</label>
                  </div>
                </>
              ) : `${condition.name} - Diagnosticado: ${new Date(condition.diagnosedDate).toLocaleDateString()} - ${condition.isActive ? 'Activa' : 'Inactiva'}`
            )}
          </div>
        </div>
      </div>
      <BottomNavbar />
    </div>
  );
};

export default MiSalud;