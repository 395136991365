import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Typography, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getBaseUrl } from '../utils/apiUtil';

function WaitingRoom() {
  const [status, setStatus] = useState('Joining...');
  const [error, setError] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [showJoinButton, setShowJoinButton] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [queuePosition, setQueuePosition] = useState(null);

  const navigate = useNavigate();

  const joinWaitingRoom = useCallback(async () => {
    try {
      await axios.post(`${getBaseUrl()}/api/waiting-room/join`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setStatus('Waiting');
      // Immediately check queue position after joining
      await checkQueuePosition();
    } catch (error) {
      console.error('Failed to join waiting room', error);
      if (error.response && error.response.status === 400 && error.response.data.error === 'Patient already in waiting room') {
        setStatus('Waiting');
        // Check queue position if already in waiting room
        await checkQueuePosition();
      } else {
        setError('Failed to join waiting room. Please try again.');
        setStatus('Error');
      }
    }
  }, []);

  const checkQueuePosition = useCallback(async () => {
    try {
      const response = await axios.get(`${getBaseUrl()}/api/waiting-room/check-position-in-waiting-room`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      const qp = response.data.position + 1;
      setQueuePosition(qp);
      const estimatedWaitTime = qp * 300; // 5 minutes per patient in seconds
      setEndTime(Date.now() + estimatedWaitTime * 1000);
    } catch (error) {
      console.error('Failed to check queue position', error);
    }
  }, []);

  const checkActiveConsultation = useCallback(async () => {
    try {
      const response = await axios.get(`${getBaseUrl()}/api/waiting-room/check-active-consultation`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      if (response.data.isActive) {
        navigate('/consultation');
      }
    } catch (error) {
      console.error('Failed to check active consultation', error);
    }
  }, [navigate]);

  useEffect(() => {
    joinWaitingRoom();
    const consultationInterval = setInterval(checkActiveConsultation, 10000); // Check every 10 seconds

    return () => {
      clearInterval(consultationInterval);
    };
  }, [joinWaitingRoom, checkActiveConsultation]);

  useEffect(() => {
    if (status === 'Waiting') {
      const positionInterval = setInterval(checkQueuePosition, 30000); // Check every 30 seconds
      return () => clearInterval(positionInterval);
    }
  }, [status, checkQueuePosition]);

  useEffect(() => {
    const messages = [
      'Prepara tu Historia: Ten a mano tus síntomas y medicaciones actuales para ayudar a tu médico.',
      'Conexión Estable: Asegúrate de estar en un lugar con buena señal de internet.',
      'Ten tus Preguntas: Escribe las preguntas que quieres tratar para no olvidar nada.'
    ];

    let index = 0;

    setInfoMessage(messages[index]);
    index = (index + 1) % messages.length;

    const messageInterval = setInterval(() => {
      setInfoMessage(messages[index]);
      index = (index + 1) % messages.length;
    }, 10000);

    return () => clearInterval(messageInterval);
  }, []);

  useEffect(() => {
    if (endTime) {
      const intervalId = setInterval(() => {
        const now = Date.now();
        const timeLeft = Math.max(0, endTime - now);
        setRemainingTime(Math.floor(timeLeft / 1000));
      }, 1000);
  
      return () => clearInterval(intervalId);
    }
  }, [endTime]);

  const handleStartConsultation = () => {
    navigate('/consultation');
  };

  if (error) {
    return (
      <div>
        <Typography color="error">{error}</Typography>
        <Button onClick={joinWaitingRoom}>Try Again</Button>
      </div>
    );
  }
  
  if (status !== 'Waiting') {
    return <CircularProgress style={{ color: 'white', margin: 'auto' }} />;
  }

  return (
    <div style={{
      padding: '20px',
      background: 'linear-gradient(to bottom, #3f51b5, #2196f3)',
      color: 'white',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <ArrowBackIcon style={{ marginRight: '10px', cursor: 'pointer' }} onClick={() => navigate(-1)} />
        <Typography variant="h5">MiMedicoONLINE</Typography>
      </div>
      <Typography variant="h6" style={{ marginBottom: '20px' }}>Sala de espera</Typography>
      <div style={{ 
        backgroundColor: 'rgba(255,255,255,0.2)', 
        borderRadius: '50%', 
        width: '200px', 
        height: '200px', 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center',
        margin: '0 auto 20px'
      }}>
        <Typography variant="h3">
          {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, '0')}
        </Typography>
        <Typography variant="caption">min estimados</Typography>
      </div>
      {queuePosition !== null && (
        <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '20px' }}>
          Tu posición en la cola: {queuePosition}
        </Typography>
      )}
      <div style={{ height: '1px', backgroundColor: 'white', margin: '20px 0' }} />
      <Typography style={{ marginBottom: '20px' }}>
        {infoMessage}
      </Typography>
      {showJoinButton ? (
        <Button variant="contained" color="secondary" onClick={handleStartConsultation}>
          Iniciar consulta
        </Button>
      ) : (
        <CircularProgress style={{ color: 'white', alignSelf: 'center' }} />
      )}
    </div>
  );
}

export default WaitingRoom;