import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff, ChevronLeft } from 'lucide-react';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Register() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword || confirmPassword === '');
  }, [password, confirmPassword]);

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email) || email === '');
  }, [email]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await axios.post(`${getBaseUrl()}/api/users/send-otp`, { mobile });
      setStep(2);
    } catch (error) {
      console.error('Failed to send OTP', error);
      setError('Failed to send OTP. Please try again.');
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await axios.post(`${getBaseUrl()}/api/users/verify-otp`, { mobile, otp });
      setStep(3);
    } catch (error) {
      console.error('OTP verification failed', error);
      setError('El código de verificación es incorrecto. Por favor, inténtalo de nuevo.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (!passwordsMatch) {
      setError('Passwords do not match');
      return;
    }
    if (!isEmailValid) {
      setError('Invalid email format');
      return;
    }
    try {
      const res = await axios.post(`${getBaseUrl()}/api/users/register`, { 
        name: `${name} ${surname}`, 
        email, 
        password, 
        mobile,
        dateOfBirth,
        gender,
        role: 'patient' 
      });
      localStorage.setItem('token', res.data.token);
      navigate('/');
    } catch (error) {
      console.error('Registration failed', error);
      setError('Registration failed. Please try again.');
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <form onSubmit={handleSendOtp} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Primer nombre
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0"
                placeholder="Ingresa tu primer nombre"
                required
              />
            </div>
            <div>
              <label htmlFor="surname" className="block text-sm font-medium text-gray-700 mb-1">
                Apellido
              </label>
              <input
                type="text"
                id="surname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0"
                placeholder="Ingresa tu apellido"
                required
              />
            </div>
            <div>
              <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-1">
                Fecha de nacimiento
              </label>
              <input
                type="date"
                id="dateOfBirth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0"
                required
              />
            </div>
            <div>
              <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                Sexo
              </label>
              <select
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0"
                required
              >
                <option value="">Seleccionar</option>
                <option value="male">Hombre</option>
                <option value="female">Mujer</option>
                <option value="other">Otro</option>
              </select>
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                Número de celular sin 0 y sin 15
              </label>
              <PhoneInput
                country={'ar'}
                value={mobile}
                onChange={setMobile}
                countryCodeEditable={false}
                autoFormat={true}
                inputStyle={{
                  width: '100%',
                  height: '48px',
                  fontSize: '1rem',
                  borderRadius: '0.5rem',
                  backgroundColor: '#F3F4F6',
                  border: 'none',
                }}
                inputProps={{
                  required: true,
                  className: 'w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0',
                }}
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Continuar
            </button>
          </form>
        );
      case 2:
        return (
          <form onSubmit={handleVerifyOtp} className="space-y-6">
            <div>
              <label htmlFor="otp" className="block text-sm font-medium text-gray-700 mb-1">
                Recibiras un SMS con un código de verificación
              </label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0"
                placeholder="Ingresar código de verificación"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Continuar
            </button>
          </form>
        );
      case 3:
        return (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0 ${
                  isEmailValid ? '' : 'border-red-500'
                }`}
                placeholder="Ingresa tu email"
                required
              />
              {!isEmailValid && (
                <p className="mt-1 text-sm text-red-500">Por favor ingresa un email válido.</p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Crear contraseña
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0"
                  placeholder="Contraseña (8 o más caracteres)"
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Confirmar contraseña
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`w-full px-4 py-3 rounded-lg bg-gray-100 border-transparent focus:border-blue-500 focus:bg-white focus:ring-0 ${
                    passwordsMatch ? '' : 'border-red-500'
                  }`}
                  placeholder="Confirmar contraseña"
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400" />
                  )}
                </button>
              </div>
              {!passwordsMatch && (
                <p className="mt-1 text-sm text-red-500">Las contraseñas no coinciden.</p>
              )}
            </div>
            <div className="flex items-center">
              <input
                id="terms"
                type="checkbox"
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                required
              />
              <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
                He leido y acepto los <a href="#" className="text-blue-600 underline">Terminos y Condiciones</a> y la <a href="#" className="text-blue-600 underline">Politica de Privacidad</a>
              </label>
            </div>
            <button
              type="submit"
              className="w-full py-3 px-4 border border-transparent rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={!passwordsMatch || !isEmailValid}
            >
              Crear cuenta
            </button>
          </form>
        );
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <nav className="bg-blue-500 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <button onClick={() => navigate(-1)} className="text-white">
            <ChevronLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-white">Crear Cuenta</h1>
          <div className="w-6"></div> {/* Espacio reservado para alineación a la derecha */}
        </div>
      </nav>
      <div className="max-w-md mx-auto mt-8 px-4">
        <div className="flex mb-8">
          <div className={`flex-1 text-center pb-2 ${step === 1 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-400'}`}>Tus Datos</div>
          <div className={`flex-1 text-center pb-2 ${step === 2 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-400'}`}>Verificar Celular</div>
          <div className={`flex-1 text-center pb-2 ${step === 3 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-400'}`}>Contraseña</div>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {renderStep()}
        <p className="mt-6 text-center text-sm text-gray-600">
          ¿Ya tienes una cuenta?{' '}
          <button
            className="font-medium text-blue-600 hover:text-blue-500"
            onClick={() => navigate('/login')}
          >
            Iniciar sesión
          </button>
        </p>
      </div>
    </div>
  );
}

export default Register;