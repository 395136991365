import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bell, Calendar, ArrowLeft, Clock, X } from 'lucide-react';
import { getBaseUrl } from '../utils/apiUtil';

const DoctorSelection = ({ open, onClose, onAppointmentBooked }) => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [bookingSuccess, setBookingSuccess] = useState(false);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/appointments/available-doctors`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setDoctors(response.data);
      } catch (error) {
        console.error('Error fetching doctors:', error);
        setSnackbar({ open: true, message: 'Error al obtener la lista de médicos', severity: 'error' });
      }
    };

    if (open) {
      fetchDoctors();
      setSelectedDoctor(null);
      setBookingSuccess(false);
    }
  }, [open]);

  const handleDoctorSelect = async (doctor) => {
    setSelectedDoctor(doctor);
    try {
      const startDate = new Date().toISOString().split('T')[0];
      const endDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      const response = await axios.get(`${getBaseUrl()}/api/appointments/available-slots/${doctor._id}`, {
        params: { startDate, endDate },
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setAvailableSlots(response.data);
    } catch (error) {
      console.error('Error fetching available slots:', error);
      setSnackbar({ open: true, message: 'Error al obtener los horarios disponibles', severity: 'error' });
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleBookAppointment = async () => {
    if (!selectedDate || !selectedTime) {
      setSnackbar({ open: true, message: 'Por favor, selecciona fecha y hora', severity: 'warning' });
      return;
    }

    try {
      const response = await axios.post(`${getBaseUrl()}/api/appointments/book`, {
        doctorId: selectedDoctor._id,
        date: selectedDate,
        startTime: selectedTime,
        endTime: calculateEndTime(selectedTime)
      }, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });

      if (response.data && response.data._id) {
        setSnackbar({ open: true, message: 'Turno reservado exitosamente', severity: 'success' });
        onAppointmentBooked(response.data);
        setBookingSuccess(true);
      } else {
        throw new Error('Respuesta inválida del servidor');
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
      setSnackbar({ open: true, message: 'Error al reservar el turno: ' + (error.response?.data?.error || error.message), severity: 'error' });
    }
  };

  const calculateEndTime = (startTime) => {
    const [hours, minutes] = startTime.split(':');
    const endTime = new Date(2000, 0, 1, parseInt(hours), parseInt(minutes));
    endTime.setHours(endTime.getHours() + 1);
    return `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;
  };

  const getNext7Days = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const formattedDate = date.toISOString().split('T')[0];
      const hasSlots = availableSlots.some(slot => slot.date === formattedDate);
      days.push({ date, formattedDate, hasSlots });
    }
    return days;
  };

  const formatDateCompact = (date) => {
    const options = { weekday: 'short', day: 'numeric' };
    const formatted = date.toLocaleDateString('es-ES', options);
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  };

  const getAvailableTimesForDate = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return availableSlots
      .filter(slot => slot.date === formattedDate)
      .map(slot => slot.startTime);
  };

  const handleBack = () => {
    setSelectedDoctor(null);
    setSelectedDate(null);
    setSelectedTime(null);
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-50 rounded-xl max-w-md w-full p-6 font-sans">
        <header className="flex justify-between items-center mb-6">
          <div className="text-blue-600 font-bold text-xl">+mi medico online</div>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-6 h-6" />
          </button>
        </header>

        {bookingSuccess ? (
          <div className="text-center py-6">
            <h2 className="text-2xl font-bold mb-4">Turno Reservado</h2>
            <p className="text-gray-600 mb-4">
              Tienes una cita con Dr. {selectedDoctor.name} el{' '}
              {new Date(selectedDate).toLocaleDateString('es-ES', { weekday: 'long', day: 'numeric', month: 'long' })} a las{' '}
              {selectedTime}.
            </p>
            <button
              className="bg-blue-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
              onClick={onClose}
            >
              Cerrar
            </button>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-6">
              {selectedDoctor ? (
                <button className="flex items-center text-blue-600 hover:text-blue-700" onClick={handleBack}>
                  <ArrowLeft className="mr-2 w-5 h-5" /> Atrás
                </button>
              ) : (
                'Elegí tu médico'
              )}
            </h2>

            {!selectedDoctor ? (
              <div className="space-y-4">
                {doctors.map((doctor) => (
                  <button
                    key={doctor._id}
                    className="w-full bg-white border border-gray-200 rounded-xl p-4 text-left hover:bg-gray-50 transition-colors"
                    onClick={() => handleDoctorSelect(doctor)}
                  >
                    <h3 className="font-semibold text-lg">Dr. {doctor.name}</h3>
                    <p className="text-gray-500">{doctor.specialization || 'General'}</p>
                  </button>
                ))}
              </div>
            ) : (
              <>
                <h3 className="text-xl font-semibold mb-4">¿Qué día te gustaría reservar?</h3>
                <div className="grid grid-cols-7 gap-2 mb-6">
                  {getNext7Days().map(({ date, formattedDate, hasSlots }) => (
                    <button
                      key={formattedDate}
                      className={`p-2 rounded-lg flex flex-col items-center justify-center ${
                        selectedDate === formattedDate
                          ? 'bg-blue-600 text-white'
                          : hasSlots
                          ? 'bg-sky-100 text-blue-600'
                          : 'bg-gray-100 text-gray-400'
                      } ${!hasSlots && 'opacity-50 cursor-not-allowed'}`}
                      onClick={() => hasSlots && handleDateSelect(formattedDate)}
                      disabled={!hasSlots}
                    >
                      <span className="text-xs">{formatDateCompact(date).split(' ')[0]}</span>
                      <span className="text-sm font-semibold">{formatDateCompact(date).split(' ')[1]}</span>
                    </button>
                  ))}
                </div>

                {selectedDate && (
                  <>
                    <h3 className="text-xl font-semibold mb-4">¿A qué hora?</h3>
                    <div className="grid grid-cols-3 gap-2 mb-6">
                      {getAvailableTimesForDate(new Date(selectedDate)).map((time) => (
                        <button
                          key={time}
                          className={`p-2 rounded-lg flex items-center justify-center ${
                            selectedTime === time
                              ? 'bg-blue-600 text-white'
                              : 'bg-sky-100 text-blue-600'
                          } hover:bg-blue-700 hover:text-white transition-colors`}
                          onClick={() => handleTimeSelect(time)}
                        >
                          <Clock className="w-4 h-4 mr-1" />
                          <span>{time}</span>
                        </button>
                      ))}
                    </div>
                  </>
                )}

                <button
                  className={`w-full py-3 rounded-lg font-semibold ${
                    selectedDate && selectedTime
                      ? 'bg-blue-600 text-white hover:bg-blue-700'
                      : 'bg-gray-200 text-gray-500 cursor-not-allowed'
                  } transition-colors`}
                  onClick={handleBookAppointment}
                  disabled={!selectedDate || !selectedTime}
                >
                  Reservar Turno
                </button>
              </>
            )}
          </>
        )}
      </div>

      {snackbar.open && (
        <div className={`fixed bottom-4 left-4 right-4 p-4 rounded-lg ${
          snackbar.severity === 'success' ? 'bg-green-500' : 'bg-red-500'
        } text-white shadow-lg`}>
          {snackbar.message}
        </div>
      )}
    </div>
  );
};

export default DoctorSelection;