import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';
import { Bell, ChevronLeft, Edit2, Plus, X } from 'lucide-react';
import BottomNavbar from './BottomNavbar';

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [medicalProfile, setMedicalProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [healthcareInsurance, setHealthcareInsurance] = useState({
    tipo: 'Ninguna',
    nombre: '',
    numeroAfiliado: '',
    plan: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axios.get(`${getBaseUrl()}/api/users/me`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setUser(userResponse.data);

        const medicalProfileResponse = await axios.get(`${getBaseUrl()}/api/medical-profiles`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setMedicalProfile(medicalProfileResponse.data);
        setHealthcareInsurance(medicalProfileResponse.data.healthcareInsurance || {
          tipo: 'Ninguna',
          nombre: '',
          numeroAfiliado: '',
          plan: ''
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHealthcareInsurance(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${getBaseUrl()}/api/medical-profiles`, 
        { healthcareInsurance },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setMedicalProfile(response.data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating healthcare insurance:', error);
    }
  };

  return (
    <div className="mx-auto bg-gray-50 min-h-screen pb-20 font-sans">
      <div className="p-6">
        <header className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <ChevronLeft className="text-gray-400 mr-2 cursor-pointer" onClick={() => navigate(-1)} />
            <div className="text-blue-600 font-bold text-xl">Perfil</div>
          </div>
          <Bell className="text-gray-400" />
        </header>

        {user && (
          <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
            <h2 className="text-2xl font-bold mb-4">Información Personal</h2>
            <div className="space-y-2">
              <p><span className="font-semibold">Nombre completo:</span> {user.name}</p>
              <p><span className="font-semibold">Teléfono:</span> {user.mobile}</p>
              <p><span className="font-semibold">Email:</span> {user.email}</p>
              <p><span className="font-semibold">Fecha de nacimiento:</span> {new Date(user.dateOfBirth).toLocaleDateString()}</p>
            </div>
          </div>
        )}

        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Plan de Salud</h2>
            <button onClick={handleEditToggle} className="text-blue-600">
              {isEditing ? <X /> : <Edit2 />}
            </button>
          </div>
          <p className="text-gray-600 mb-4">
            Completá los datos de tu cobertura médica, obra social o prepaga, para acceder al servicio
          </p>
          {isEditing ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block mb-1">Tipo:</label>
                <select 
                  name="tipo" 
                  value={healthcareInsurance.tipo} 
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="Ninguna">Ninguna</option>
                  <option value="Obra Social">Obra Social</option>
                  <option value="Prepaga">Prepaga</option>
                </select>
              </div>
              <div>
                <label className="block mb-1">Nombre:</label>
                <input 
                  type="text" 
                  name="nombre" 
                  value={healthcareInsurance.nombre} 
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Número de afiliado:</label>
                <input 
                  type="text" 
                  name="numeroAfiliado" 
                  value={healthcareInsurance.numeroAfiliado} 
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Plan:</label>
                <input 
                  type="text" 
                  name="plan" 
                  value={healthcareInsurance.plan} 
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                Guardar
              </button>
            </form>
          ) : (
            <div className="space-y-2">
              <p><span className="font-semibold">Tipo:</span> {healthcareInsurance.tipo}</p>
              <p><span className="font-semibold">Nombre:</span> {healthcareInsurance.nombre}</p>
              <p><span className="font-semibold">Número de afiliado:</span> {healthcareInsurance.numeroAfiliado}</p>
              <p><span className="font-semibold">Plan:</span> {healthcareInsurance.plan}</p>
            </div>
          )}
        </div>
      </div>
      <BottomNavbar />
    </div>
  );
};

export default Profile;