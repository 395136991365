import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavbar from './BottomNavbar';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  Button, 
  Box, 
  IconButton,
  Grid,
  Snackbar,
  CircularProgress
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import DoctorSelection from './DoctorSelection';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';
import AppointmentBookingPage from './AppointmentBookingPage';
import { Bell, Calendar, Home, Heart, User, MapPin, Plus, ChevronRight } from 'lucide-react';

const Homepage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [openDoctorSelection, setOpenDoctorSelection] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [isInWaitingRoom, setIsInWaitingRoom] = useState(false);
  const [waitingRoomPosition, setWaitingRoomPosition] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isConsultationActive, setIsConsultationActive] = useState(false);
  const [isBookingAppointment, setIsBookingAppointment] = useState(false);

  const checkActiveConsultation = useCallback(async () => {
    try {
      const response = await axios.get(`${getBaseUrl()}/api/waiting-room/check-active-consultation`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setIsConsultationActive(response.data.isActive);
    } catch (error) {
      console.error('Failed to check active consultation', error);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/users/me`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchAppointments = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/appointments/my-appointments`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setAppointments(response.data);
      } catch (error) {
        console.error('Error fetching appointments:', error);
      }
    };

    const checkWaitingRoomStatus = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/waiting-room/check-position-in-waiting-room`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        const position = response.data.position;
        setIsInWaitingRoom(position !== -1);
        setWaitingRoomPosition(position !== -1 ? position : null);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setIsInWaitingRoom(false);
          setWaitingRoomPosition(null);
        } else {
          console.error('Error checking waiting room status:', error);
        }
      }
    };

    fetchUserData();
    fetchAppointments();
    checkWaitingRoomStatus();
    checkActiveConsultation(); // Check immediately on component mount

    const waitingRoomInterval = setInterval(checkWaitingRoomStatus, 30000);
    const consultationInterval = setInterval(checkActiveConsultation, 10000); // Check every 10 seconds

    return () => {
      clearInterval(waitingRoomInterval);
      clearInterval(consultationInterval);
    };
  }, [checkActiveConsultation]);

  const handleOpenDoctorSelection = () => {
    setOpenDoctorSelection(true);
  };

  const handleCloseDoctorSelection = () => {
    setOpenDoctorSelection(false);
  };

  const handleAppointmentBooked = (newAppointment) => {
    setAppointments([...appointments, newAppointment]);
  };

  const handleLeaveWaitingRoom = async () => {
    try {
      await axios.post(`${getBaseUrl()}/api/waiting-room/leave`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setIsInWaitingRoom(false);
      setWaitingRoomPosition(null);
      setSnackbarMessage('Has salido de la sala de espera');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error leaving waiting room:', error);
      setSnackbarMessage('Error al salir de la sala de espera');
      setSnackbarOpen(true);
    }
  };

  const handleEnterWaitingRoom = () => {
    navigate('/waiting-room');
  };

  const handleEnterConsultation = () => {
    navigate('/consultation');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleOpenAppointmentBooking = () => {
    navigate('/book');
  };


  return (
    <div className="mx-auto bg-gray-50 min-h-screen pb-20 font-sans">
      <div className="p-6">
      <header className="flex justify-between items-center mb-6">
        <div className="text-blue-600 font-bold text-xl">+mi medico online</div>
        <Bell className="text-gray-400" />
      </header>

      {user && (
        <h2 className="text-2xl font-bold mb-4">Hola, {user.name}</h2>
      )}

      <h1 className="text-3xl font-bold mb-6">Conecta con un medico</h1>

      {isConsultationActive && (
        <div className="bg-green-500 text-white rounded-xl shadow-sm p-4 mb-4">
          <div className="flex items-center justify-center mb-2">
            <Calendar className="w-10 h-10 mr-2" />
            <h3 className="font-semibold text-lg">Consulta Activa</h3>
          </div>
          <p className="text-center mb-2">Tienes una consulta activa en este momento</p>
          <button 
            className="bg-white text-green-500 w-full py-2 rounded-lg font-semibold hover:bg-green-50"
            onClick={handleEnterConsultation}
          >
            Entrar a la consulta
          </button>
        </div>
      )}

      <div className="space-y-4 mb-6">
        <div className="bg-white rounded-xl shadow-sm p-4 flex items-center" onClick={handleOpenAppointmentBooking}>
          <div className="bg-sky-100 p-3 rounded-xl mr-4 w-16 h-16 flex items-center justify-center">
            <Calendar className="text-blue-600 w-8 h-8" />
          </div>
          <div>
            <h3 className="font-semibold text-lg">Programar un turno</h3>
            <p className="text-gray-500">Agenda un turno programado con un medico</p>
            <p className="text-gray-400 text-sm">Generalista y especialistas</p>
          </div>
        </div>

        <div className="bg-blue-600 text-white rounded-xl shadow-sm p-4">
          <div className="flex items-center justify-center mb-2">
            <Plus className="w-10 h-10 mr-2" />
            <h3 className="font-semibold text-lg">Atención Inmediata</h3>
          </div>
          {isInWaitingRoom ? (
            <>
              <p className="text-center mb-2">Te encuentras en sala de espera.</p>
              <div className="flex items-center justify-center mb-2">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white mr-2"></div>
                <p className="text-sm">Posición en la cola: {waitingRoomPosition + 1}</p>
              </div>
              <p className="text-center text-sm mb-2">Recibirás una notificación cuando sea tu turno</p>
              {!isConsultationActive && (
                <>
                  <button 
                    className="bg-sky-400 w-full py-2 rounded-lg font-semibold mb-2 hover:bg-sky-500"
                    onClick={handleEnterWaitingRoom}
                  >
                    Ver la sala de espera
                  </button>
                  <button 
                    className="bg-red-500 w-full py-2 rounded-lg font-semibold hover:bg-red-600"
                    onClick={handleLeaveWaitingRoom}
                  >
                    Salir de la sala de espera
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <p className="text-center mb-2">Entra a la sala de espera para atención inmediata</p>
              <button 
                className="bg-sky-400 w-full py-2 rounded-lg font-semibold hover:bg-sky-500"
                onClick={handleEnterWaitingRoom}
              >
                Entrar a la sala de espera
              </button>
            </>
          )}
        </div>
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Mis Turnos</h2>
          <button className="text-gray-500 text-sm flex items-center">
            Ver todos <ChevronRight className="w-4 h-4 ml-1" />
          </button>
        </div>
        {appointments.length > 0 ? (
          appointments.map((appointment) => (
            <div key={appointment._id} className="bg-white rounded-xl shadow-sm p-4 mb-4">
              <div className="flex items-center mb-3">
                <div className="mr-4 text-center">
                  <div className="text-3xl font-bold">{new Date(appointment.date).getDate()}</div>
                  <div className="text-sm text-gray-500">
                    {new Date(appointment.date).toLocaleString('default', { month: 'short' })}
                  </div>
                </div>
                <div>
                  <h3 className="font-semibold text-lg">Dr. {appointment.doctorId.name}</h3>
                  <p className="text-gray-500">{appointment.doctorId.specialization || 'Consulta General'}</p>
                  <p className="text-gray-500">{appointment.startTime} - {appointment.endTime}</p>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center bg-sky-100 px-3 py-1 rounded-full">
                  <MapPin className="w-4 h-4 text-blue-600 mr-1" />
                  <span className="text-sm text-gray-600">Virtual</span>
                </div>
                <button className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold">
                  Mas detalles
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">No tienes turnos programados.</p>
        )}
      </div>

      <BottomNavbar />
    </div>
    </div>
  );
};

export default Homepage;