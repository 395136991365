import React from 'react';
import { ArrowLeft, Video } from 'lucide-react';
import {useNavigate} from 'react-router-dom';

const AppointmentConfirmationPage = ({ appointment, onClose, onAddToCalendar, onViewAppointments }) => {
  const navigate = useNavigate();
  // Add a function to format the time
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return new Date(0, 0, 0, hours, minutes).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const goToHome = () => {
    navigate('/');
  };
  return (
    <div className="flex flex-col h-screen bg-indigo-900 text-white font-sans">
      <header className="p-4 flex items-center">
        <button onClick={onClose} className="text-white p-2">
          <ArrowLeft size={24} />
        </button>
      </header>

      <main className="flex-grow flex flex-col px-4 pb-4 overflow-y-auto">
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold mb-2">¡Todo listo!</h1>
          <p className="text-lg text-indigo-200">Preparate para tu turno</p>
        </div>

        <div className="bg-white text-indigo-900 rounded-3xl p-6 flex-grow flex flex-col">
          <div className="bg-indigo-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
            <svg className="w-10 h-10 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>

          <h2 className="text-xl font-bold text-center mb-6">
            {appointment.patientName}, agendá la fecha y hora del turno en tu calendario.
          </h2>

          <div className="flex items-center mb-6">
            <div className="bg-indigo-100 text-indigo-900 rounded-xl p-3 text-center mr-4">
              <span className="block text-3xl font-bold">{new Date(appointment.date).getDate()}</span>
              <span className="block text-sm">{new Date(appointment.date).toLocaleString('default', { month: 'long' })}</span>
            </div>
            <div>
              <h3 className="font-bold text-lg">Dr. {appointment.doctorName}</h3>
              <p className="text-gray-600">{appointment.appointmentType}</p>
              <p className="text-gray-600">
                {formatTime(appointment.startTime)} - {formatTime(appointment.endTime)}
              </p>
            </div>
          </div>

          <div className="bg-indigo-50 rounded-xl p-4 mb-6">
            <h3 className="font-bold mb-2 uppercase text-sm">TIPO</h3>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-semibold">Consulta Virtual</p>
                <p className="text-sm text-gray-600">Por favor ingresa a la llamada a traves de la app el día y hora del turno</p>
              </div>
              <Video className="text-indigo-500" size={24} />
            </div>
          </div>

          <button
            onClick={goToHome}
            className="w-full bg-indigo-600 text-white py-3 rounded-xl font-semibold mt-auto"
          >
            Volvé al inicio
          </button>
        </div>
      </main>
    </div>
  );
};

export default AppointmentConfirmationPage;