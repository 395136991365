import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import WaitingRoom from './components/WaitingRoom';
import Consultation from './components/Consultation';
import Homepage from './components/Homepage';
import AppointmentBookingPage from './components/AppointmentBookingPage';
import Profile from './components/Profile';
import MiSalud from './components/MyHealth';
import { isTokenValid } from './utils/authUtils';

console.log(process.env.REACT_APP_BACKEND_API_URL);

function App() {
  useEffect(() => {
    // Check token validity on app load
    if (!isTokenValid()) {
      localStorage.removeItem('token');
    }
  }, []);

  const PrivateRoute = ({ children }) => {
    return isTokenValid() ? children : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={!isTokenValid() ? <Login /> : <Navigate to="/" />} />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/" 
            element={
              <PrivateRoute>
                <Homepage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/waiting-room" 
            element={
              <PrivateRoute>
                <WaitingRoom />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/consultation" 
            element={
              <PrivateRoute>
                <Consultation />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/book" 
            element={
              <PrivateRoute>
                <AppointmentBookingPage />
              </PrivateRoute>
            }
          />
          <Route 
            path="/profile" 
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route 
            path="/myhealth" 
            element={
              <PrivateRoute>
                <MiSalud />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;