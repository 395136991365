import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Typography, Button, CircularProgress, IconButton } from '@mui/material';
import { connect, createLocalVideoTrack } from 'twilio-video';
import { useNavigate } from 'react-router-dom';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { getBaseUrl } from '../utils/apiUtil';

function Consultation() {
  const [token, setToken] = useState(null);
  const [roomName, setRoomName] = useState(null);
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [currentCameraId, setCurrentCameraId] = useState(null);
  

  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const navigate = useNavigate();

  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      setPermissionGranted(true);
    } catch (error) {
      console.error('Error getting user media:', error);
      alert('Failed to get camera and microphone permissions. Please check your browser settings.');
    }
  };

  const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    console.log('Video devices:', videoDevices);
    if (videoDevices.length > 0) {
      setCurrentCameraId(videoDevices[0].deviceId);
    }
  };

  useEffect(() => {
    getVideoDevices();
  }, []);

  useEffect(() => {
    const checkActiveConsultation = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/waiting-room/check-active-consultation`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        if (response.data.isActive) {
          console.log(response.data);
          setRoomName(response.data.consultationData.roomName);
          fetchToken(response.data.consultationData.roomName);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking active consultation:', error);
        navigate('/waiting-room');
      }
    };

    checkActiveConsultation();
  }, [navigate]);

  const fetchToken = async (roomName) => {
    try {
      const response = await axios.post(`${getBaseUrl()}/api/video/token`, 
        { roomName }, 
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      setToken(response.data.token);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Twilio token:', error);
      alert('Failed to fetch video token. Please try again.');
      navigate('/waiting-room');
    }
  };

  useEffect(() => {
    if (token && roomName) {
      startVideoCall(token, roomName);
    }
  }, [token, roomName]);

  const startVideoCall = async (token, roomName) => {
    try {
      if (!permissionGranted) {
        await requestPermissions();
      }
      const videoRoom = await connect(token, {
        name: roomName,
        preferredVideoCodecs: ['VP8', 'H264'],
        networkQuality: { local: 1, remote: 1 },
        audio: true,
        video: { width: 1024, height: 1080, frameRate: 30, deviceId: currentCameraId },
      });

      setRoom(videoRoom);
      setParticipants(Array.from(videoRoom.participants.values()));

      const localTrack = await createLocalVideoTrack();
      const localVideoElement = localTrack.attach();
      localVideoElement.style.width = '100%';
      localVideoElement.style.height = '100%';
      localVideoElement.style.objectFit = 'cover';
      localVideoRef.current.appendChild(localVideoElement);

      videoRoom.on('participantConnected', participantConnected);
      videoRoom.on('participantDisconnected', participantDisconnected);
      videoRoom.participants.forEach(participantConnected);

    } catch (error) {
      console.error('Error starting video call', error);
      handleVideoError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVideoError = (error) => {
    if (error.name === 'NotAllowedError') {
      alert('Camera or microphone permission denied. Please check your browser settings.');
    } else if (error.name === 'NotFoundError') {
      alert('No camera or microphone found. Please check your device.');
    } else {
      alert('An error occurred while starting the video call. Please try again.');
    }
  };

  const endCall = async () => {
    //try {
    //  await axios.post(`${getBaseUrl()}/api/waiting-room/end-consultation`, {}, {
    //    headers: { 'x-auth-token': localStorage.getItem('token') }
    //  });
    //} catch (error) {
    //  console.error('Error ending consultation:', error);
    //}
  
    if (room) {
      room.disconnect();
    }
    navigate('/');
  };

  const handleFileUpload = (event) => {
    setAttachments([...attachments, ...event.target.files]);
  };

  const uploadAttachment = async () => {
    try {
      const formData = new FormData();
      formData.append('patientId', '6684265ba17acda8b316c112');
      formData.append('date', new Date().toISOString());
      formData.append('diagnosis', 'user provided');
      formData.append('treatment', 'user provided');
      formData.append('notes', 'user provided');

      attachments.forEach((file) => {
        formData.append('attachments', file);
      });

      await axios.post('http://localhost:5005/api/medical-history/add', formData, {
        headers: { 
          'x-auth-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });

      alert('Attachment uploaded successfully');
      setAttachments([]);
    } catch (error) {
      console.error('Error uploading attachment:', error);
      alert('Failed to upload attachment. Please try again.');
    }
  };

  const participantConnected = (participant) => {
    setParticipants(prevParticipants => [...prevParticipants, participant]);
    participant.tracks.forEach(track => trackPublished(track, participant));
    participant.on('trackPublished', track => trackPublished(track, participant));
  };

  const participantDisconnected = (participant) => {
    setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    remoteVideoRef.current.innerHTML = '';
  };

  const trackPublished = (trackPublication, participant) => {
    if (trackPublication.track) {
      trackSubscribed(trackPublication.track, participant);
    }
    trackPublication.on('subscribed', track => trackSubscribed(track, participant));
  };

  const trackSubscribed = (track, participant) => {
    if (track.kind === 'video') {
      const videoElement = track.attach();
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';
      videoElement.style.objectFit = 'cover';
      videoElement.style.objectPosition = 'center center';
      remoteVideoRef.current.innerHTML = '';
      remoteVideoRef.current.appendChild(videoElement);
    } else if (track.kind === 'audio') {
      const audioElement = track.attach();
      remoteAudioRef.current.appendChild(audioElement);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: '#3f51b5', color: 'white', position: 'relative' }}>
      <Typography variant="h5" style={{ padding: '16px' }}>Consulta médica</Typography>
      {loading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          <div style={{ position: 'relative', flex: 1, minHeight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div 
              style={{
                width: '100%', // Adjust this value to control zoom level
                height: '80%', // Adjust this value to control zoom level
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#000000',
              }}
            >
            <div 
              ref={remoteVideoRef} 
              style={{ 
                width: '100%', 
                height: '100%', 
                backgroundColor: '#f0f0f0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden'
              }}
            >
              {participants.length === 0 && <Typography style={{ color: '#3f51b5' }}>Esperando que ingrese el medico</Typography>}
            </div>
            </div>
            <audio ref={remoteAudioRef} autoPlay />
            <div 
              ref={localVideoRef} 
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                width: '40%',
                maxWidth: '200px',
                aspectRatio: '16 / 9',
                zIndex: 1,
                overflow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
                background: '#000'
              }}
            ></div>
          </div>
          <div style={{ 
            position: 'absolute', 
            bottom: 40, 
            left: 0, 
            right: 0, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            gap: '20px'
          }}>
            <IconButton
              onClick={endCall}
              style={{ 
                backgroundColor: '#f50057',
                color: 'white',
                padding: '12px',
              }}
            >
              <CallEndIcon fontSize="large" />
            </IconButton>
            <input
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="raised-button-file">
              <IconButton
                component="span"
                style={{ 
                  backgroundColor: '#4caf50',
                  color: 'white',
                  padding: '12px',
                }}
              >
                <AttachFileIcon fontSize="large" />
              </IconButton>
            </label>
            {attachments.length > 0 && (
              <IconButton
                onClick={uploadAttachment}
                style={{ 
                  backgroundColor: '#2196f3',
                  color: 'white',
                  padding: '12px',
                }}
              >
                <SendIcon fontSize="large" />
              </IconButton>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Consultation;