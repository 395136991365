import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Home, Calendar, Heart, User } from 'lucide-react';

const BottomNavbar = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 py-2">
      <div className="max-w-md mx-auto flex justify-between items-center px-6">
        <Link to="/" className="flex flex-col items-center">
          <Home className={isActive('/') ? 'text-blue-600' : 'text-gray-400'} size={24} />
          <span className={`text-xs mt-1 ${isActive('/') ? 'text-blue-600' : 'text-gray-400'}`}>Inicio</span>
        </Link>
        <Link to="/book" className="flex flex-col items-center">
          <Calendar className={isActive('/book') ? 'text-blue-600' : 'text-gray-400'} size={24} />
          <span className={`text-xs mt-1 ${isActive('/book') ? 'text-blue-600' : 'text-gray-400'}`}>Turnos</span>
        </Link>
        <Link to="/myhealth" className="flex flex-col items-center">
          <Heart className={isActive('/myhealth') ? 'text-blue-600' : 'text-gray-400'} size={24} />
          <span className={`text-xs mt-1 ${isActive('/myhealth') ? 'text-blue-600' : 'text-gray-400'}`}>Mi Salud</span>
        </Link>
        <Link to="/profile" className="flex flex-col items-center">
          <User className={isActive('/profile') ? 'text-blue-600' : 'text-gray-400'} size={24} />
          <span className={`text-xs mt-1 ${isActive('/profile') ? 'text-blue-600' : 'text-gray-400'}`}>Perfil</span>
        </Link>
      </div>
    </nav>
  );
};

export default BottomNavbar;