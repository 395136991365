import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getBaseUrl } from '../utils/apiUtil';
import { ArrowLeft, X, Clock, Home, Calendar, Heart, User } from 'lucide-react';
import BottomNavbar from './BottomNavbar';
import AppointmentConfirmationPage from './AppointmentConfirmationPage';

const AppointmentBookingPage = ({ onClose, onAppointmentBooked }) => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState('selectDoctor'); // 'selectDoctor', 'selectDateTime', 'confirm'
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await axios.get(`${getBaseUrl()}/api/appointments/available-doctors`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setDoctors(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching doctors:', error);
        setSnackbar({ open: true, message: 'Error al obtener la lista de médicos', severity: 'error' });
        setLoading(false);
      }
    };

    fetchDoctors();
  }, []);

  const handleDoctorSelect = async (doctor) => {
    setSelectedDoctor(doctor);
    try {
      const startDate = new Date().toISOString().split('T')[0];
      const endDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
      const response = await axios.get(`${getBaseUrl()}/api/appointments/available-slots/${doctor._id}`, {
        params: { startDate, endDate },
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setAvailableSlots(response.data);
      setStage('selectDateTime');
    } catch (error) {
      console.error('Error fetching available slots:', error);
      setSnackbar({ open: true, message: 'Error al obtener los horarios disponibles', severity: 'error' });
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleBookAppointment = async () => {
    try {

        const selectedSlot = availableSlots.find(slot => 
            slot.date === selectedDate && slot.startTime === selectedTime
        );

        setSelectedSlot(selectedSlot);
        
        const response = await axios.post(`${getBaseUrl()}/api/appointments/book`, {
            doctorId: selectedDoctor._id,
            date: selectedDate,
            startTime: selectedTime,
            endTime: selectedSlot.endTime, // Use the endTime from the selected slot
        }, {
            headers: { 'x-auth-token': localStorage.getItem('token') }
        });

      if (response.data && response.data._id) {
        setIsConfirmed(true);
        onAppointmentBooked(response.data);
      } else {
        throw new Error('Respuesta inválida del servidor');
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
      setSnackbar({ open: true, message: 'Error al reservar el turno: ' + (error.response?.data?.error || error.message), severity: 'error' });
    }
  };

  const calculateEndTime = (startTime) => {
    const [hours, minutes] = startTime.split(':');
    const endTime = new Date(2000, 0, 1, parseInt(hours), parseInt(minutes));
    endTime.setHours(endTime.getHours() + 1);
    return `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}`;
  };

  const getNext7Days = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const formattedDate = date.toISOString().split('T')[0];
      const hasSlots = availableSlots.some(slot => slot.date === formattedDate);
      days.push({ date, formattedDate, hasSlots });
    }
    return days;
  };

  const formatDateCompact = (date) => {
    const options = { weekday: 'short', day: 'numeric' };
    const formatted = date.toLocaleDateString('es-ES', options);
    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  };

  const getAvailableTimesForDate = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return availableSlots
      .filter(slot => slot.date === formattedDate)
      .map(slot => slot.startTime);
  };

  const handleBack = () => {
    switch (stage) {
      case 'selectDateTime':
        setStage('selectDoctor');
        setSelectedDoctor(null);
        break;
      case 'confirm':
        setStage('selectDateTime');
        break;
      default:
        onClose();
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }
  if (isConfirmed) {
    return (
      <AppointmentConfirmationPage
        appointment={{
          patientName: "Scott", // You'll need to get this from user data
          doctorName: selectedDoctor.name,
          date: selectedDate,
          startTime: selectedTime,
          endTime: selectedSlot.endTime, // Use the endTime from the selected slot
          appointmentType: "Consulta", // You might want to add this to your doctor or appointment data
        }}
        onClose={() => {
          setIsConfirmed(false);
          onClose();
        }}
        onAddToCalendar={() => {
          // Implement calendar integration logic here
          console.log("Adding to calendar...");
        }}
      />
    );
  }

  return (
    <div className="flex flex-col h-screen bg-gray-50 font-sans">
      <header className="flex justify-between items-center p-6 bg-white">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold">Turnos</h1>
        </div>
        <div className="text-blue-600 font-bold text-xl">+mi medico online</div>
      </header>

      <main className="flex-grow overflow-y-auto p-6 pb-20">
        {stage === 'selectDoctor' && (
          <>
            <h2 className="text-xl font-semibold mb-4">Seleccionar Médico</h2>
            <div className="space-y-2">
              {doctors.map((doctor) => (
                <button
                  key={doctor._id}
                  className="w-full bg-white border border-gray-200 rounded-xl p-4 text-left hover:bg-gray-50 transition-colors"
                  onClick={() => handleDoctorSelect(doctor)}
                >
                  <h3 className="font-semibold text-lg">Dr. {doctor.name}</h3>
                  <p className="text-gray-500">{doctor.specialization || 'General'}</p>
                </button>
              ))}
            </div>
          </>
        )}

        {stage === 'selectDateTime' && (
          <>
            <h2 className="text-xl font-semibold mb-4">¿Qué día te gustaría reservar?</h2>
            <div className="grid grid-cols-7 gap-2 mb-6">
              {getNext7Days().map(({ date, formattedDate, hasSlots }) => (
                <button
                  key={formattedDate}
                  className={`p-2 rounded-lg flex flex-col items-center justify-center ${
                    selectedDate === formattedDate
                      ? 'bg-blue-600 text-white'
                      : hasSlots
                      ? 'bg-sky-100 text-blue-600'
                      : 'bg-gray-100 text-gray-400'
                  } ${!hasSlots && 'opacity-50 cursor-not-allowed'}`}
                  onClick={() => hasSlots && handleDateSelect(formattedDate)}
                  disabled={!hasSlots}
                >
                  <span className="text-xs">{formatDateCompact(date).split(' ')[0]}</span>
                  <span className="text-sm font-semibold">{formatDateCompact(date).split(' ')[1]}</span>
                </button>
              ))}
            </div>

            {selectedDate && (
              <>
                <h2 className="text-xl font-semibold mb-4">¿A qué hora?</h2>
                <div className="grid grid-cols-3 gap-2 mb-6">
                  {getAvailableTimesForDate(new Date(selectedDate)).map((time) => (
                    <button
                      key={time}
                      className={`p-2 rounded-lg flex items-center justify-center ${
                        selectedTime === time
                          ? 'bg-blue-600 text-white'
                          : 'bg-sky-100 text-blue-600'
                      } hover:bg-blue-700 hover:text-white transition-colors`}
                      onClick={() => handleTimeSelect(time)}
                    >
                      <Clock className="w-4 h-4 mr-1" />
                      <span>{time}</span>
                    </button>
                  ))}
                </div>
              </>
            )}

            <button
              className={`w-full py-3 rounded-lg font-semibold ${
                selectedDate && selectedTime
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-200 text-gray-500 cursor-not-allowed'
              } transition-colors`}
              onClick={() => setStage('confirm')}
              disabled={!selectedDate || !selectedTime}
            >
              Continuar
            </button>
          </>
        )}

        {stage === 'confirm' && (
          <>
            <h2 className="text-xl font-semibold mb-4">Confirmar Turno</h2>
            <div className="bg-white rounded-xl shadow-sm p-4 mb-6">
              <p className="mb-2"><span className="font-semibold">Doctor:</span> Dr. {selectedDoctor.name}</p>
              <p className="mb-2"><span className="font-semibold">Fecha:</span> {new Date(selectedDate).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
              <p><span className="font-semibold">Hora:</span> {selectedTime}</p>
            </div>

            <button
              className="w-full py-3 rounded-lg font-semibold bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              onClick={handleBookAppointment}
            >
              Confirmar Reserva
            </button>
          </>
        )}
      </main>

      <BottomNavbar />

      {snackbar.open && (
        <div className={`fixed bottom-20 left-4 right-4 p-4 rounded-lg ${
          snackbar.severity === 'success' ? 'bg-green-500' : 'bg-red-500'
        } text-white shadow-lg`}>
          {snackbar.message}
        </div>
      )}

      {loading && (
        <div className="absolute inset-0 bg-gray-50 bg-opacity-75 flex justify-center items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
    </div>
  );
};

export default AppointmentBookingPage;